import React, { useState, useEffect } from "react";
import Table from "../../components/table/table.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CreateAdminForm from "../../components/addAdminModal/AddAdminModal.component";
import addIcon from "../../assets/add_circle.svg";
import SuccessPopup from "../../components/successPopup/SuccessPopup.component";
import ErrorPopup from "../../components/failPopup/FailPopup.component";
import EditAdminDialog from "../../components/editAdminModal/EditAdminModal.component";

const Admins = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [switchStates, setSwitchStates] = useState([]);
  const [open, setOpen] = useState(false);

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [editAdminData, setEditAdminData] = useState(null);
  const [editAdminDialogOpen, setEditAdminDialogOpen] = useState(false);
  // const [successMessage, setSuccessMessage] = useState('');

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage(""); // Reset the error message when closing the popup
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: "white", // thumb - unchecked
            transform: "translate(8px,8px)",
            "&.Mui-checked": {
              transform: "translate(17px,8px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "#BEE108",
              },
            },
          },
          thumb: {
            width: 15, // set the desired width
            height: 15, // set the desired height
          },
          colorPrimary: {
            "&.Mui-checked": {
              color: "white", // thumb - checked
            },
          },
          track: {
            opacity: 1, // track - unchecked
            backgroundColor: "#ED674B",
            width: "58px",
            height: "26px",
            borderRadius: "13px",
            ".Mui-checked.Mui-checked + &": {
              opacity: 1, // track - checked
              backgroundColor: "#BEE108",
            },
          },
        },
      },
    },
  });

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "firstName",
      Cell: ({ row }) => (
        <span>{`${row.original.firstName} ${row.original.lastName}`}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "roleName",
    },
    {
      Header: "Phone number",
      accessor: "phoneNumber",
    },
    {
      Header: "Status",
      accessor: "isEnabled",
      textAlign: "center",
      Cell: ({ row }) => (
        <ThemeProvider theme={theme}>
          <Switch
            checked={switchStates[row.index] || false}
            onChange={(event) =>
              handleChange(event, row.index, row.original.id, row)
            }
          />
        </ThemeProvider>
      ),
    },
  ];

  const fetchData = async ({ pageIndex, pageSize }) => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `/admin/admins?page=${pageIndex + 1}&size=${pageSize}`
      );
      setTotalElements(response.data.totalElements);
      setData(response.data.data);
      const initialSwitchStates = response.data.data.map(
        (item) => item.isEnabled
      );
      setSwitchStates(initialSwitchStates);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchData({ pageIndex: 0, pageSize: 10 });
  }, []);

  const handleChange = async (event, index, adminId) => {
    event.stopPropagation();
    setSwitchStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
    try {
      const response = await axiosPrivate.patch(`/admin/admins/enable`, {
        id: adminId,
        isEnabled: !switchStates[index],
      });
      setSuccessOpen(true);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || "An unexpected error occurred."
      );
      setErrorOpen(true);
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdminEdit = (adminData,event) => {
    // console.log(adminData);
    if (!(event.target instanceof HTMLInputElement)) {
      // Open the admin form dialog and pass the admin data
      // You can use state or a modal component for handling the dialog
      // Example using state:
      setEditAdminDialogOpen(true);
      setEditAdminData(adminData);
    }
  };

  return (
    <>
      <div className="flex justify-end items-center w-full mb-[18px]">
        <button
          className="flex items-center bg-[#1C1C1E] text-[#EFFF99] text-[16px] p-[12px] leading-[16px] rounded-[10px]"
          onClick={handleOpen}
        >
          <img className=" mr-[12px]" src={addIcon} alt="" />
          Add Admin
        </button>
      </div>
      <CreateAdminForm
        open={open}
        onClose={handleClose}
        fetchData={fetchData}
      />
      <div className="h-[calc(100%-80px)] overflow-hidden">
        <div className="h-full overflow-scroll">
          <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            totalElements={totalElements}
            loading={loading}
            onCellClick={handleAdminEdit}
          />
        </div>
        <SuccessPopup
          open={successOpen}
          message="Success!"
          onClose={handleSuccessClose}
        />

        <ErrorPopup
          open={errorOpen}
          message={errorMessage}
          onClose={handleErrorClose}
        />
        {editAdminData && (
          <EditAdminDialog
            open={editAdminDialogOpen}
            onClose={() => setEditAdminDialogOpen(false)}
            adminData={editAdminData}
            fetchData = {fetchData}
          />
        )}
      </div>
    </>
  );
};

export default Admins;
