import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logiIcon from "../../assets/logo-icon.svg";

const SideNavBar = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full pt-[32px] pb-[68px] py-[20px] overflow-y-auto bg-white relative">
          <div className="w-full flex flex-col items-center xl:mb-[66px] mb-[38px]">
            <div className=" h-[90px] w-[90px] rounded-[50%] overflow-hidden mb-[24px]">
              <img src={logiIcon} alt="profile" className=" w-full " />
            </div>
            <p className="text-[#121F49] text-[20px] leading-[30px] font-['Poppins-SemiBold']">
              {" "}
              {userInfo?.firstName?.toUpperCase()}{" "}
              {userInfo?.lastName?.toUpperCase()}
            </p>
            <p className="text-[#A5A3A9] text-[14px] leading-[21px]">
              {" "}
              {(userInfo?.role?.toLowerCase().charAt(0).toUpperCase() +
                userInfo?.role?.toLowerCase().slice(1)).replace(/_/g, ' ')}
            </p>
          </div>

          <ul className="flex flex-col flex-1 justify-between">
            <div className="flex-1">
              <li>
                <Link to="/dashboard">
                  <div className="flex items-center px-[20px] text-[#1C1C1E] hover:bg-[#1C1C1E] hover:text-[#EFFF99] h-[48px] group">
                    <svg
                      className="w-[22] h-[22px] transition duration-75 group-hover:text-[#EFFF99] text-[#1C1C1E]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 21"
                    >
                      <path d="M13 9V3H21V9H13ZM3 13V3H11V13H3ZM13 21V11H21V21H13ZM3 21V15H11V21H3ZM5 11H9V5H5V11ZM15 19H19V13H15V19ZM15 7H19V5H15V7ZM5 19H9V17H5V19Z" />
                    </svg>
                    <span className="ml-[12px] text-[16px]">Dashboard</span>
                  </div>
                </Link>
              </li>
              {userInfo.role === 'super_admins' ? (
                <li className="">
                  <Link to="admins">
                    <div className="flex items-center px-[20px] text-[#1C1C1E] hover:bg-[#1C1C1E] hover:text-[#EFFF99] h-[48px] group">
                      <svg
                        className="w-[22] h-[22px] transition duration-75 group-hover:text-[#EFFF99] text-[#1C1C1E]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M12 13C11.0167 13 10.1875 12.6625 9.5125 11.9875C8.8375 11.3125 8.5 10.4833 8.5 9.5C8.5 8.51667 8.8375 7.6875 9.5125 7.0125C10.1875 6.3375 11.0167 6 12 6C12.9833 6 13.8125 6.3375 14.4875 7.0125C15.1625 7.6875 15.5 8.51667 15.5 9.5C15.5 10.4833 15.1625 11.3125 14.4875 11.9875C13.8125 12.6625 12.9833 13 12 13ZM12 11C12.4333 11 12.7917 10.8583 13.075 10.575C13.3583 10.2917 13.5 9.93333 13.5 9.5C13.5 9.06667 13.3583 8.70833 13.075 8.425C12.7917 8.14167 12.4333 8 12 8C11.5667 8 11.2083 8.14167 10.925 8.425C10.6417 8.70833 10.5 9.06667 10.5 9.5C10.5 9.93333 10.6417 10.2917 10.925 10.575C11.2083 10.8583 11.5667 11 12 11ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM12 4.125L6 6.375V11.1C6 12 6.125 12.875 6.375 13.725C6.625 14.575 6.96667 15.375 7.4 16.125C8.1 15.775 8.83333 15.5 9.6 15.3C10.3667 15.1 11.1667 15 12 15C12.8333 15 13.6333 15.1 14.4 15.3C15.1667 15.5 15.9 15.775 16.6 16.125C17.0333 15.375 17.375 14.575 17.625 13.725C17.875 12.875 18 12 18 11.1V6.375L12 4.125ZM12 17C11.4 17 10.8167 17.0667 10.25 17.2C9.68333 17.3333 9.14167 17.5167 8.625 17.75C9.10833 18.25 9.63333 18.6833 10.2 19.05C10.7667 19.4167 11.3667 19.7 12 19.9C12.6333 19.7 13.2333 19.4167 13.8 19.05C14.3667 18.6833 14.8917 18.25 15.375 17.75C14.8583 17.5167 14.3167 17.3333 13.75 17.2C13.1833 17.0667 12.6 17 12 17Z" />
                      </svg>
                      <span className="flex-1 ml-[12px] whitespace-nowrap text-[16px]">
                        Admins
                      </span>
                    </div>
                  </Link>
                </li>
              ) : (
                <></>
              )}
              <li className="">
                <Link to="events">
                  <div className="flex items-center px-[20px] text-[#1C1C1E] hover:bg-[#1C1C1E] hover:text-[#EFFF99] h-[48px] group">
                    <svg
                      className="w-[22px] h-[22px] transition duration-75 group-hover:text-[#EFFF99] text-[#1C1C1E]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 21"
                    >
                      <path d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM4 9V19H20V9H4ZM6 13H11V17H6V13Z" />
                    </svg>
                    <span className="flex-1 ml-[12px] whitespace-nowrap text-[16px]">
                      Events
                    </span>
                  </div>
                </Link>
              </li>
            </div>
            <li className="absolute bottom-[54px]">
              <div
                onClick={handleLogout}
                className="flex items-center px-[20px] text-[#ED674B] h-[48px] group cursor-pointer"
              >
                <svg
                  className="w-[22px] h-[22px] text-[#1C1C1E]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path
                    d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12V5H5V19H12V21H5ZM16 17L14.625 15.55L17.175 13H9V11H17.175L14.625 8.45L16 7L21 12L16 17Z"
                    fill="#ED674B"
                  />
                </svg>
                <span className="flex-1 ml-[12px] whitespace-nowrap text-[16px]">
                  Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </aside>

      <div className="p-4 sm:ml-64"></div>
    </>
  );
};
export default SideNavBar;
