import "./login.styles.scss";
import logo from "../../assets/logo.svg";
import logoIcon from "../../assets/logo-icon.svg";
import warningIcon from "../../assets/warning.svg";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email && password) {
      try {
        const response = await axios.post(
          "/admin/auth/login",
          JSON.stringify({ email, password }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const accessToken = response?.data?.data?.token;
        const refreshToken = response?.data?.data?.refreshToken;

        setAuth({ email, password, accessToken, refreshToken });
        localStorage.clear();
        localStorage.setItem("access-token", accessToken);
        localStorage.setItem("refresh-token", refreshToken);
        localStorage.setItem("userInfo", JSON.stringify(response?.data.data));

        setEmail("");
        setPassword("");
        navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
          setError("No Server Response");
        }
        if (err.code === 401) {
          setError(err?.message);
        } else {
          setError("Invalid Credentials");
        }
      }
    } else {
      setError("Please Provide Email and Password");
    }
  };

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  useEffect(() => {
    setError("");
  }, [email, password]);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <div className="bg-[#F9F9F9] flex h-full">
      <div className="login-bg flex items-center w-[50%]">
        <div className="layer flex justify-center">
          <img src={logo} alt="louco Logo" className="w-[444px]" />
        </div>
      </div>

      <div className="flex flex-col space-between h-full xl:py-[51px] xl:px-[87px] sm:py-[25px] sm:px-[40px] py-[30px] px-[18px]  w-[50%] text-[#1E1E1E] ">
        <img
          className="lg:w-[140px] w-[100px]  lg:mb-[50px] mb-[25px]"
          src={logoIcon}
          alt="logo"
        />

        <form className="relative" onSubmit={handleLogin}>
          <p className="lg:text-[40px] text-[30px] font-[700] font-['Poppins-SemiBold'] leading-[44px]">
            Log In to our platform
          </p>
          <div
            className="mb-[24px] lg:mt-[54px] mt-[27px] "
            data-te-input-wrapper-init
          >
            <label
              htmlFor="email-address"
              className="pointer-events-none text-[#1E1E1E] text-base font-[600] mb-[8px] block"
            >
              Email
            </label>

            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email-address"
              type="email"
              ref={emailRef}
              required
              autoComplete="off"
              className="block lg:min-h-[64px] min-h-[40px] w-full border-[0.3px] border-[#A7A7A7] rounded-[8px] bg-white indent-3 py-[0.32rem] leading-[1.6] outline-none "
              placeholder="&#xf0e0;  Enter your email"
            />
          </div>

          <div className="relative mb-4" data-te-input-wrapper-init>
            <label
              htmlFor="password"
              className="pointer-events-none text-[#1E1E1E] text-base font-[600] mb-[8px] block"
            >
              Password
            </label>

            <input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              id="password"
              type={isPasswordVisible ? "text" : "password"}
              required
              autoComplete="off"
              maxLength={50}
              className="placeholder block lg:min-h-[64px] min-h-[40px] w-full border-[0.3px] border-[#A7A7A7] rounded-[8px] bg-white indent-3 py-[0.32rem] leading-[1.6] outline-none"
              placeholder="&#xf023;  Enter your password"
            />

            <button
              className="absolute lg:top-[53px] top-[42px] right-0 px-4 text-[#A7A7A7]"
              onClick={togglePasswordVisibility}
              type="button"
            >
              {isPasswordVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </button>
          </div>
          <div>
            <div
              className={`${
                error.length ? "block" : "hidden"
              } shadow-[0_4px_4px_0px_rgba(0,0,0,0.05)] bg-[#FAD3CA] border-l-[14px] border-[#EC674B] text-[#ED674B] text-700 p-4 rounded-[12px] h-[48px] xl:mt-[80px] mt-[40px] xl:mb-[80px] mb-[40px]`}
              role="alert"
            >
              <span className="flex items-center">
                {" "}
                <img
                  src={warningIcon}
                  className="inline-block h-[24px] pr-[12px]"
                  alt=""
                />
                <p className="font-bold inline-block text-[16px] leading-[16px]">
                  {error}
                </p>
              </span>
            </div>

            <div className="pb-1 pt-1 text-center xl:mt-[87px]">
              <button
                className="disabled:bg-[#A7A7A7] h-[64px] inline-block w-full rounded-[8px] px-6 pb-2 pt-2.5 text-lg leading-normal text-white transition duration-150 ease-in-out focus:outline-none bg-[#03A7FF]"
                type="submit"
                value="Login"
                data-te-ripple-init
                data-te-ripple-color="light"
                disabled={email.length && password.length ? false : true}
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
