import React, { useEffect } from "react";
import logo from '../../assets/logo.svg'


const Dashboard = () => {

  return (
    <>
    <div className="h-full w-full flex items-center justify-center">
      <img src={logo}/>
    </div>
    </>
  );
};

export default Dashboard;