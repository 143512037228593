import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"; // eslint-disable-line no-unused-vars
import "./App.css";

import Home from "./pages/layout/Layout.component"; // eslint-disable-line no-unused-vars
import Dashboard from "./pages/dashboard/dashboard.component";
import Admins from "./pages/admins/admins.component";
import Events from "./pages/events/events.component";
import Login from "./pages/login/login.component";
import SidebarLayout from "./components/sideBarLayout/sideBarLayout.component";
import RequireAuth from "./components/RequireAuth";
import Layout from "./pages/layout/Layout.component";

function App() {
  const role = JSON.parse(localStorage?.getItem("userInfo"))?.role;

  return (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth />} >
              <Route element={<SidebarLayout/>}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    {role === "super_admins" && <Route path="admins" element={<Admins />} />}
                    <Route path="events" element={<Events />} />
                </Route>
            </Route>

          </Route>
        </Routes>
  );

}

export default App;
