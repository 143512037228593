import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import closeIcon from '../../assets/cancel.svg'
import checkIcon from '../../assets/tick-circle.svg'

const SuccessPopup = ({ open, message, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "189px",
          width: "520px",
          borderRadius:"20px",
        },
      }}
    >
      <DialogTitle style={{ position: "relative", width:'100%',display:'flex',alignItems:'center',padding:'25px 16px 8px 16px' }}>
      <img
          src={closeIcon}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
            fontSize: "18px",
            color: "#666",
          }}
          onClick={onClose}
          alt=""
        />
      <img
          src={checkIcon}
          style={{
            color: "#BEE108",
            margin: "0 auto",
            fontSize: "42px", // Adjust the size as needed
            background: "#F9F9F9",
            borderRadius: "50%", // Make it a circle
            padding: "14px", // Add padding for spacing
            border: "1px solid rgba(165, 163, 169, 0.25)",
          }}
          alt=""
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: "16px", textAlign: "center", color:"#1C1C1E", fontFamily:"Poppins-regular" }}>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessPopup;
