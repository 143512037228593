import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import closeIcon from "../../assets/cancel.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SuccessPopup from "../successPopup/SuccessPopup.component";
import ErrorPopup from "../failPopup/FailPopup.component";
import {
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import uploadIcon from "../../assets/upload-icon.svg";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import dayjs, { Dayjs } from "dayjs";


const CreateEventForm = ({ open, onClose, fetchData }) => {
  const axiosPrivate = useAxiosPrivate();

  const CREATE_EVENT_URL = "/admin/events";
  const CATEGORIES_URL = "/admin/categories";
  const COUNTIRES_URL = "/admin/countries";
  const CITIES_URL = "/admin/cities";

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [validImage, setValidImage] = useState(true);
  const [imageError, setImageError] = useState("");

  const [eventName, setEventName] = useState("");
  const [validEventName, setValidEventName] = useState(true);

  const [eventFromPrice, setEventFromPrice] = useState(null);
  const [validEventFromPrice, setValidEventFromPrice] = useState(true);

  const [eventToPrice, setEventToPrice] = useState(null);

  const [eventDescription, setEventDescription] = useState("");
  const [validEventDescription, setValidEventDescription] = useState(true);

  const [eventSummary, setEventSummary] = useState("");
  const [validEventSummary, setValidEventSummary] = useState(true);

  const [eventUrl, setEventUrl] = useState("");
  const [validEventUrl, setValidEventUrl] = useState(true);

  const [venueName, setVenueName] = useState("");
  const [validVenueName, setValidVenueName] = useState(true);

  const [streetName, setStreetName] = useState("");
  const [validStreetName, setValidStreetName] = useState(true);

  const [postalCode, setPostalCode] = useState("");
  const [validPostalCode, setValidPostalCode] = useState(true);

  const [selectedDate, setSelectedDate] = useState(null);
  const [validSelectedDate, setValidSelectedDate] = useState(true);

  const [selectedTime, setSelectedTime] = useState(null);
  const [validSelectedTime, setValidSelectedTime] = useState(true);

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [validSelectedCategory, setValidSelectedCategory] = useState(true);

  const [selectSubCategory, setSelectSubCategory] = useState([]);
  const [selectedSubCategoryIds, setSelectedSubCategoryIds] = useState([]);
  const [validSelectedSubCategory, setValidSelectedSubCategory] = useState(true);

  const [selectCity, setSelectCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [validSelectedCity, setValidSelectedCity] = useState(true);

  const [selectCountry, setSelectCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [validSelectedCountry, setValidSelectedCountry] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const pageSize = 50; // Number of items per page

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subCategoryDisabled, setSubCategoryDisabled] = useState(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const fetchSelectorData = async (url, setOptions) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(url);
      setOptions(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSelectorSearchData = async (searchTerm, newPage = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(CITIES_URL, {
        params: {
          page: newPage,
          size: pageSize,
          keyword: searchTerm ? searchTerm : null,
        },
      });
      if (newPage === 1) {
        setSelectCity(response.data.data);
      } else {
        setSelectCity((prevOptions) => [...prevOptions, ...response.data.data]);
      }
      setPage(newPage);
      setHasMore(newPage < response.data.totalPages);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial data for select fields from backend
    if (open) {
      fetchSelectorData(CATEGORIES_URL, setSelectCategory);
      fetchSelectorSearchData("");
      fetchSelectorData(COUNTIRES_URL, setSelectCountry);
    }
  }, [open]);

  useEffect(() => {
    if (selectedCategoryIds.length) {
      fetchSelectorData(
        `/admin/subcategories?categoryId=${selectedCategoryIds}`,
        setSelectSubCategory
      );
      setSelectedSubCategoryIds([]);
      setSubCategoryDisabled(false);
    } else {
      setSelectedSubCategoryIds([]);
      setSelectSubCategory([])
      setSubCategoryDisabled(true);
    }
  }, [selectedCategoryIds]);

  useEffect(() => {
    fetchSelectorSearchData(searchTerm);
  }, [searchTerm]);

  const handleInputChange = (event, value) => {
    setSearchTerm(value);
    setPage(1);
  };

  const handleScroll = () => {
    if (page >= 1 && !isLoading && hasMore) {
      fetchSelectorSearchData(searchTerm, page + 1);
    }
  };

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];
    if (!selectedImage) return; // if user cancels the file selection

    const acceptedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "video/mp4",
      "video/quicktime",
      "video/x-msvideo",
    ];

    if (!acceptedFormats.includes(selectedImage.type)) {
      setImageError("Please upload only PNG, JPEG, JPG, or PDF files.");
      return;
    } else {
      setImageError("");
      setValidImage(true);
    }
    if (selectedImage.size > 1024 * 1024) {
      setImageError("Image size exceeds the limit of 1MB");
      return;
    } else {
      setImageError("");
      setValidImage(true);
    }

    setIsImageLoading(true);

    const formData = new FormData();
    formData.append("file", selectedImage);

    try {
      const response = await axiosPrivate.post("/media", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setImageUrl(response.data.data.mediaUrl);
      setIsImageLoading(false);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || "Error uploading image"
      );
      setErrorOpen(true);
      setIsImageLoading(false);
    }
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    onClose();
    resetFields();
    fetchData({ pageIndex: 0, pageSize: 10 });
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage(""); // Reset the error message when closing the popup
  };

  const handleCancel = () => {
    onClose();
    resetFields();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const eventDate = selectedDate ? dayjs(selectedDate.$d).format('YYYY-MM-DD') : null;
    const hours =
      selectedTime?.hour() < 10
        ? `0${selectedTime?.hour()}`
        : selectedTime?.hour();
    const minutes =
      selectedTime?.minute() < 10
        ? `0${selectedTime?.minute()}`
        : selectedTime?.minute();
    const eventTime = hours || minutes ? `${hours}:${minutes}` : null;

    e.preventDefault();
    if (!eventName.length) {
      setValidEventName(false);
    } else {
      setValidEventName(true);
    }

    if (eventFromPrice <= 0) {
      setValidEventFromPrice(false);
    } else {
      setValidEventFromPrice(true);
    }

    if (!eventUrl.length) {
      setValidEventUrl(false);
    } else {
      setValidEventUrl(true);
    }

    if (!eventDescription.length) {
      setValidEventDescription(false);
    } else {
      setValidEventDescription(true);
    }

    if (!eventSummary.length) {
      setValidEventSummary(false);
    } else {
      setValidEventSummary(true);
    }

    if (!venueName.length) {
      setValidVenueName(false);
    } else {
      setValidVenueName(true);
    }

    if (!streetName.length) {
      setValidStreetName(false);
    } else {
      setValidStreetName(true);
    }

    if (!eventDate?.length) {
      setValidSelectedDate(false);
    } else {
      setValidSelectedDate(true);
    }
    if (!eventTime?.length) {
      setValidSelectedTime(false);
    } else {
      setValidSelectedTime(true);
    }

    if (!imageUrl.length) {
      setValidImage(false);
    } else {
      setValidImage(true);
    }

    if (postalCode.length !== 5) {
      setValidPostalCode(false);
    } else {
      setValidPostalCode(true);
    }

    if (!selectedCountry?.id) {
      setValidSelectedCountry(false);
    } else {
      setValidSelectedCountry(true);
    }
    if (typeof selectedCity !== "number") {
      setValidSelectedCity(false);
    } else {
      setValidSelectedCity(true);
    }
    if (!selectedSubCategoryIds.length) {
      setValidSelectedSubCategory(false);
    } else {
      setValidSelectedSubCategory(true);
    }
    if (!selectedCategoryIds.length) {
      setValidSelectedCategory(false);
    } else {
      setValidSelectedCategory(true);
    }

    if (
      !eventName.length ||
      eventFromPrice <= 0 ||
      !eventUrl.length ||
      !eventDescription.length ||
      !eventSummary.length ||
      !venueName.length ||
      !streetName.length ||
      !selectedCategoryIds.length ||
      !selectedSubCategoryIds.length ||
      typeof selectedCity !== "number" ||
      !selectedCountry?.id ||
      postalCode.length !== 5||
      !imageUrl.length ||
      !eventDate.length ||
      !eventTime.length
    ) {
      return;
    }
    try {
      const response = await axiosPrivate.post(
        CREATE_EVENT_URL,
        JSON.stringify({
          eventName: eventName,
          eventDate: eventDate,
          imageUrl: imageUrl,
          eventSummary: eventSummary,
          eventDescription: eventDescription,
          fromPrice: eventFromPrice,
          toPrice: eventToPrice ? eventToPrice : null,
          venueName: venueName,
          streetName: streetName,
          countryId: selectedCountry.id,
          cityId: selectedCity,
          postalCode: postalCode,
          subCategoryIds: selectedSubCategoryIds,
          ticketsUrl: eventUrl,
          categoryId: selectedCategoryIds,
          eventTime: `${hours}:${minutes}`,
        })
      );

      //clear state and controlled inputs
      //need value attrib on inputs for this
      resetFields();
      setSuccessOpen(true);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || "An unexpected error occurred."
      );
      setErrorOpen(true);
    }
  };
  const resetFields = () => {
    setImage(null);
    setImageUrl("");
    setValidImage(true);
    setImageError("");

    setEventName("");
    setValidEventName(true);

    setEventFromPrice("");
    setValidEventFromPrice(true);

    setEventToPrice("");

    setEventDescription("");
    setValidEventDescription(true);

    setEventSummary("");
    setValidEventSummary(true);

    setEventUrl("");
    setValidEventUrl(true);

    setVenueName("");
    setValidVenueName(true);

    setStreetName("");
    setValidStreetName(true);

    setPostalCode("");
    setValidPostalCode(true);

    setSelectedDate(null);
    setValidSelectedDate(true);

    setSelectedTime(null);
    setValidSelectedTime(true);

    setSelectCategory([]);
    setSelectedCategoryIds([]);
    setValidSelectedCategory(true);

    setSelectSubCategory([]);
    setSelectedSubCategoryIds([]);
    setValidSelectedSubCategory(true);

    setSelectCity([]);
    setSelectedCity("");
    setValidSelectedCity(true);

    setSelectCountry([]);
    setSelectedCountry("");
    setValidSelectedCountry(true);

    setSearchTerm("");

    setPage(0);
    setHasMore(true);
    setTotalPages(0);
    setIsLoading(false);
  };
  const handleCategoryChange = (event) => {
    const selectedIds = event.target.value;
    setSelectedCategoryIds(selectedIds);
    setSelectedSubCategoryIds([]);
  };
  const handleSubCategoryChange = (event) => {
    const selectedSubIds = event.target.value;
    setSelectedSubCategoryIds(selectedSubIds);
  };
  return (
    <Drawer
      PaperProps={{
        sx: { width: "45%", maxWidth: "703px" },
      }}
      anchor="right"
      open={open}
    >
      <div className="p-[30px] w-full h-full">
        <div className="flex justify-between items-center mb-[22px]">
          <h1 className="text-[18px] text-[1C1C1E]">Create Event</h1>
          <img
            className="w-[24px] cursor-pointer"
            src={closeIcon}
            alt=""
            onClick={handleCancel}
          />
        </div>
        <hr className="mb-[30px]" />

        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className="flex flex-col justify-between h-[calc(100%-60px)] "
        >
          <div className="flex flex-wrap items-start">
            <p className="block text-[#1C1C1E] text-[16px] mb-[6px]">
              Event image
            </p>
            <div className="w-full mb-[24px]">
              <div className=" relative border border-[#DCDCDC] bg-[#F9F9FA] h-[88px] rounded-[12px] flex flex-col justify-center items-center ">
                <input
                  type="file"
                  id="image"
                  accept="image/*,.pdf,.txt,.docx,.mp4,.mov,.avi"
                  onChange={handleImageChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                {imageUrl ? (
                  <div className="w-full h-full">
                    <img
                      src={imageUrl}
                      alt="Uploaded"
                      onClick={() => document.getElementById("image").click()}
                      className="object-cover bg-center w-full h-full rounded-md cursor-pointer"
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={uploadIcon}
                      alt="Upload Icon"
                      className="pb-[8px]"
                    />
                    {isImageLoading ? <CircularProgress /> : <></>}
                    <p className="text-[#A5A3A9] text-[14px]">
                      Upload event Image
                    </p>
                  </>
                )}
              </div>
              <p
                id="confirmnote"
                className={
                  !validImage && !imageError.length
                    ? "instructions"
                    : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
              <p
                id="confirmnote"
                className={imageError.length ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                {imageError}
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[24px]">
              <label
                htmlFor="eventName"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                Event Name
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={eventName}
                type="text"
                id="eventName"
                autoComplete="off"
                placeholder="Enter Event Name"
                onChange={(e) => setEventName(e.target.value)}
                required
              />
              <p
                id="confirmnote"
                className={!validEventName ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-460 mb-[24px] font-['Poppins-regular']">
              <label
                htmlFor="eventUrl"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                Event URL
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={eventUrl}
                type="text"
                id="eventUrl"
                autoComplete="off"
                placeholder="Enter Event URL"
                onChange={(e) => setEventUrl(e.target.value)}
                required
              />
              <p
                id="confirmnote"
                className={!validEventUrl ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-460 mb-[24px] lg:mr-[18px] font-['Poppins-regular']">
              <p className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                Event date
              </p>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ width: "100%", fontFamily: "Poppins-regular" }}
              >
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  disablePast
                  sx={{
                    borderRadius: "12px",
                    width: "100%",
                    height: "54px",
                    fontSize: "14px",
                    color: "#A5A3A9",
                    fontFamily: "Poppins-regular",
                  }}
                  slotProps={{
                    textField: {
                      placeholder: "Select Event Date",
                      style: { fontFamily: "Poppins-regular" },
                      inputProps: {
                        sx: {
                          "&::placeholder": {
                            fontFamily: "Poppins-regular",
                          },
                        },
                      },
                      InputProps: {
                        sx: {
                          width: "100%",
                          borderRadius: "12px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "red",
                            },
                            "&:hover fieldset": {
                              borderColor: "green",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "purple",
                            },
                          },
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
              <p
                id="confirmnote"
                className={!validSelectedDate ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-460 mb-[24px] font-['Poppins-regular']">
              <p className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                Event Time
              </p>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ width: "100%", fontFamily: "Poppins-regular" }}
              >
                <TimePicker
                  inputFormat="hh:mm:ss"
                  value={selectedTime}
                  onChange={handleTimeChange}
                  ampm={false}
                  sx={{
                    borderRadius: "12px",
                    width: "100%",
                    height: "54px",
                    fontSize: "14px",
                    color: "#A5A3A9",
                    fontFamily: "Poppins-regular",
                  }}
                  slotProps={{
                    textField: {
                      placeholder: "Select Event Time",
                      style: { fontFamily: "Poppins-regular" },
                      inputProps: {
                        sx: {
                          "&::placeholder": {
                            fontFamily: "Poppins-regular",
                          },
                        },
                      },
                      InputProps: {
                        sx: {
                          width: "100%",
                          borderRadius: "12px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "red",
                            },
                            "&:hover fieldset": {
                              borderColor: "green",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "purple",
                            },
                          },
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
              <p
                id="confirmnote"
                className={!validSelectedTime ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[24px]">
              <label
                htmlFor="eventFromPrice"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                From Price of event
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={eventFromPrice}
                type="number"
                id="eventFromPrice"
                autoComplete="off"
                placeholder="Enter Event Price"
                onChange={(e) => setEventFromPrice(parseInt(e.target.value))}
                required
                min="0"
              />
              <p
                id="confirmnote"
                className={!validEventFromPrice ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-[460px] mb-[24px]">
              <label
                htmlFor="eventToPrice"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                To Price of event
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={eventToPrice}
                type="number"
                id="eventToPrice"
                autoComplete="off"
                placeholder="Enter Event Price"
                onChange={(e) => setEventToPrice(parseInt(e.target.value))}
                required
                min="0"
              />
            </div>

            <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[24px]">
              <label className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                Event Categories
              </label>
              <Select
                value={selectedCategoryIds}
                onChange={handleCategoryChange}
                placeholder="Select Event Category"
                fullWidth
                multiple
                margin="normal"
                sx={{
                  borderRadius: "12px",
                  padding: "12px 0",
                  width: "100%",
                  height: "54px",
                  fontSize: "14px",
                  color: "#A5A3A9",
                  maxHeight: "300px",
                }}
                displayEmpty
              >
                {selectCategory.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.categoryName}
                  </MenuItem>
                ))}
              </Select>
              <p
                id="confirmnote"
                className={
                  !validSelectedCategory ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>

            <div className="lg:w-[47%] w-full max-w-[460px] mb-[24px]">
              <label className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                Event Subcategories
              </label>
           
              <Select
                value={selectedSubCategoryIds}
                onChange={handleSubCategoryChange}
                placeholder="Select Event Category"
                fullWidth
                multiple
                disabled={subCategoryDisabled}
                margin="normal"
                sx={{
                  borderRadius: "12px",
                  padding: "12px 0",
                  width: "100%",
                  height: "54px",
                  fontSize: "14px",
                  color: "#A5A3A9",
                  maxHeight: "300px",
                }}
                displayEmpty
              >
                {selectSubCategory.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.subCategoryName}
                  </MenuItem>
                ))}
              </Select>
              <p
                id="confirmnote"
                className={
                  !validSelectedSubCategory ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>

            <div className="w-full mb-[24px] lg:mr-[18px]">
              <label
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                htmlFor="eventSummary"
              >
                Event Summary
              </label>

              <textarea
                id="eventSummary"
                className="w-full px-[12px] py-[17px] border border-[#DCDCDC] rounded-[12px] h-[80px]"
                name="eventSummary"
                rows="4"
                placeholder="Enter Event Summary"
                onChange={(e) => setEventSummary(e.target.value)}
              ></textarea>
              <p
                id="confirmnote"
                className={!validEventSummary ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="w-full mb-[24px] lg:mr-[18px]">
              <label
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                htmlFor="eventDescription"
              >
                Event Description
              </label>

              <textarea
                id="eventDescription"
                className="w-full px-[12px] py-[17px] border border-[#DCDCDC] rounded-[12px] h-[80px]"
                name="eventDescription"
                rows="4"
                placeholder="Enter Event Description"
                onChange={(e) => setEventDescription(e.target.value)}
              ></textarea>
              <p
                id="confirmnote"
                className={
                  !validEventDescription ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <hr className="mb-[30px] w-full" />
            <p className="text-[20px] text-[1C1C1E] w-full pb-[24px]">
              Event address
            </p>
            <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[24px]">
              <label
                htmlFor="venueName"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                Name of Venue
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={venueName}
                type="text"
                id="venueName"
                autoComplete="off"
                placeholder="Enter Name of Venue"
                onChange={(e) => setVenueName(e.target.value)}
                required
              />
              <p
                id="confirmnote"
                className={!validVenueName ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>

            <div className="lg:w-[47%] w-full max-w-[460px] mb-[24px]">
              <label
                htmlFor="streetName"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                Name of Street
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={streetName}
                type="text"
                id="streetName"
                autoComplete="off"
                placeholder="Enter Name of Street"
                onChange={(e) => setStreetName(e.target.value)}
                required
              />
              <p
                id="confirmnote"
                className={!validStreetName ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[24px]">
              <label className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                Country
              </label>
              <Select
                value={selectedCountry ? selectedCountry.id : ""}
                onChange={(e) => {
                  const CountryId = e.target.value;
                  const Country = selectCountry.find(
                    (country) => country.id === CountryId
                  );
                  setSelectedCountry(Country);
                }}
                placeholder="Select Country"
                fullWidth
                margin="normal"
                sx={{
                  borderRadius: "12px",
                  padding: "12px 0",
                  width: "100%",
                  height: "54px",
                  fontSize: "14px",
                  color: "#A5A3A9",
                  maxHeight: "300px",
                }}
                displayEmpty
                renderValue={(selected) =>
                  selectedCountry ? selectedCountry.name : "Select Country"
                }
              >
                {selectCountry.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <p
                id="confirmnote"
                className={!validSelectedCountry ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="lg:w-[47%] w-full max-w-[460px] mb-[24px]">
              <label className="block text-[#1C1C1E] text-[16px] mb-[6px]">
                City
              </label>
              <Autocomplete
                onInputChange={handleInputChange}
                options={selectCity}
                getOptionLabel={(option) => option.name}
                loading={isLoading}
                noOptionsText="No city found"
                loadingText="Loading..."
                ListboxProps={{
                  onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight ===
                      listboxNode.scrollHeight
                    ) {
                      handleScroll();
                    }
                  },
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select City"
                    variant="outlined"
                    sx={{
                      root: {
                        padding: "12px 0",
                        width: "100%",
                        height: "54px",
                        fontSize: "14px",
                        color: "#A5A3A9",
                      },
                      fieldset: {
                        borderRadius: "12px",
                        fontSize: "14px",
                        color: "#A5A3A9",
                      },
                      "& input::placeholder": {
                        fontFamily: "Poppins-regular", // Example font family
                      },
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setSelectedCity(value?.id);
                }}
              />
              <p
                id="confirmnote"
                className={!validSelectedCity ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
            <div className="w-full mb-[24px] lg:mr-[18px]">
              <label
                htmlFor="postalCode"
                className="block text-[#1C1C1E] text-[16px] mb-[6px]"
              >
                Postal Code
              </label>
              <input
                className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                value={postalCode}
                type="number"
                id="postalCode"
                autoComplete="off"
                placeholder="Enter Postal Code"
                onChange={(e) => setPostalCode(e.target.value)}
                required
              />
              <p
                id="confirmnote"
                className={!validPostalCode ? "instructions" : "offscreen"}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                This field is required.
              </p>
            </div>
          </div>
          <div className="flex justify-end pb-[34px]">
            <button
              className="mr-[24px] w-[140px] py-[16px] border border-[#ED674B] text-[#ED674B] rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className=" disabled:bg-[#A5A3A9] bg-[#03A7FF] w-[140px] py-[16px] text-white  rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
              onClick={handleSubmit}
            >
              Add
            </button>
          </div>
        </form>

        <SuccessPopup
          open={successOpen}
          message="Added Successfully"
          onClose={handleSuccessClose}
        />

        <ErrorPopup
          open={errorOpen}
          message={errorMessage}
          onClose={handleErrorClose}
        />
      </div>
    </Drawer>
  );
};

export default CreateEventForm;
