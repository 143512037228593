// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 12px 12px 0 0;
  background-color: white;
}

table tbody tr {
  border-width: 0.3px 0.3px 0 0.3px;
  border-color: rgba(165, 163, 169, 0.25);
}

table tbody tr:last-child {
  border-width: 0.3px 0.3px 0.3px 0.3px;
  border-color: rgba(165, 163, 169, 0.25);
}

th:nth-child(1) {
  border-radius: 12px 0px 0px 0px;
}

th:nth-last-child(1) {
  border-radius: 0px 12px 0px 0px;
}

th {
  text-align: left;
}

table th:first-child {
  border-radius: 12px 0px 0px 0;
}

.shadow {
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
}`, "",{"version":3,"sources":["webpack://./src/components/table/table.styles.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,yBAAA;EACA,WAAA;EACA,4BAAA;EACA,uBAAA;AACJ;;AACE;EACE,iCAAA;EACA,uCAAA;AAEJ;;AAAE;EACE,qCAAA;EACA,uCAAA;AAGJ;;AAAE;EACE,+BAAA;AAGJ;;AAAE;EACE,+BAAA;AAGJ;;AADE;EACE,gBAAA;AAIJ;;AAIE;EACE,6BAAA;AADJ;;AAMA;EACE,qDAAA;AAHF","sourcesContent":["table {\n    font-family: arial, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n    border-radius: 12px 12px 0 0 ;\n    background-color: white;\n  }\n  table tbody tr{\n    border-width: 0.3px 0.3px 0 0.3px;\n    border-color: rgba(165, 163, 169,0.25)\n  }\n  table tbody tr:last-child{\n    border-width: 0.3px 0.3px 0.3px 0.3px;\n    border-color: rgba(165, 163, 169,0.25)\n  }\n\n  th:nth-child(1) {\n    border-radius: 12px 0px 0px 0px;\n  }\n\n  th:nth-last-child(1) {\n    border-radius: 0px 12px 0px 0px;\n  }\n  th{\n    text-align: left;\n  }\n\n  // table  thead tr{\n  //   border-width:0.3px 0.3px 0 0.3px;\n  //   border-color: #EDEDEE;\n  // }\n  \n  table th:first-child{\n    border-radius:12px 0px 0px 0;\n  }\n  // table th:last-child{\n  //   border-radius:0 12px 0px 0;\n  // }\n.shadow{\n  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
