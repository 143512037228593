// Table.js
import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import EditAdminModal from "../editAdminModal/EditAdminModal.component";
import "./table.styles.scss";
import CircularProgress from "@mui/material/CircularProgress";

const Table = ({ columns, data, fetchData, loading, totalElements, onCellClick }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount:manualPageCount,
    setPageSize,
    previousPage,
    nextPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      autoResetPage: true,
    },
    usePagination
  );

  const [localPageIndex, setLocalPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);


  useEffect(() => {
    if (!loading && totalElements !== undefined && pageSize !== undefined) {
      setPageCount(Math.ceil(totalElements / pageSize));
    }
  }, [loading, totalElements, pageSize]);

  useEffect(() => {
    setLocalPageIndex(pageIndex);
  }, [pageIndex,pageSize]);


  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const handleEditClick = (admin) => {
    setSelectedAdmin(admin);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container flex items-center justify-center w-full">
          <CircularProgress size={60} thickness={4} />
        </div> // Show loader when loading
      ) : (
        <>
          <table {...getTableProps()} className="min-w-full shadow">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="bg-[#EDEDEE] h-[56px] sticky top-0 z-[1] "
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column)}
                      className="p-2 pl-[32px] text-[#1C1C1E] text-[16px] font-['Poppins-SemiBold']"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="cursor-pointer h-[56px]"
                    // onClick={() => handleEditClick(row)}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="max-w-[250px] p-2 pl-[32px] text-[14px] text-[#777] font-['Poppins-light'] overflow-hidden whitespace-nowrap"
                        onClick={(event) => onCellClick(row.original,event)}
                        data-ignore={cell.column.id === "isEnabled"}
                      >
                        <div className="truncate">{cell.render("Cell")}</div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data.length === 0 && (
            <p className=" text-center py-[40px] bg-white text-[16px] font-['Poppins-regular']">
              No data available.
            </p>
          )}
          <div className="p-4 bg-white flex items-center justify-center sticky bottom-0">
            <p className="text-[#A5A3A9] text-[16px] mr-[12px] font-['Poppins-light']">
              Per Page:
            </p>
            <select
              value={pageSize}
              onChange={(e) => {
                const newSize = Number(e.target.value);
                setPageSize(newSize);
                fetchData({ pageIndex: 0, pageSize: newSize });
              }}
              className="px-2 py-1 border rounded-[#A5A3A9] rounded-[7px] text-[16px] mr-[12px]"
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <span className="mr-[12px] font-['Poppins-light'] text-[16px] text-[#A5A3A9]">
              {localPageIndex * pageSize + 1} -{" "}
              {Math.min((localPageIndex + 1) * pageSize, totalElements)} of{" "}
              {totalElements}{" "}
              <span className="ml-[12px] text-[#6DC8B7]"> | </span>
            </span>

            <button
              onClick={() => {
                if (localPageIndex > 0) {
                  previousPage();
                  setLocalPageIndex((prev) => prev - 1);
                  fetchData({ pageIndex: localPageIndex - 1, pageSize });
                }
              }}
              className={`p-1 text-[20px]  ${
                localPageIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {"<"}
            </button>
            <button
              onClick={() => {
                nextPage();
                setLocalPageIndex((prev) => prev + 1);
                fetchData({ pageIndex: localPageIndex + 1, pageSize });
              }}
              disabled={localPageIndex === pageCount - 1}
              className={`p-1 text-[20px]${
                localPageIndex === pageCount - 1
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              {">"}
            </button>
          </div>
          {/* Edit Modal */}
          {selectedAdmin && (
            <EditAdminModal
              isOpen={isEditModalOpen}
              onClose={handleEditModalClose}
              adminData={selectedAdmin}
            />
          )}
        </>
      )}
    </>
  );
};

export default Table;
