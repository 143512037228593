// EditAdminForm.jsx
import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import closeIcon from "../../assets/cancel.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SuccessPopup from "../successPopup/SuccessPopup.component";
import ErrorPopup from "../failPopup/FailPopup.component";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const EditAdminDialog = ({ open, onClose, onSave, adminData, fetchData }) => {
  // const roles = {
  //   1: "Super Admin",
  //   2: "Admin",
  //   3: "Finance",
  //   4: "Customer Service",
  // };

  const axiosPrivate = useAxiosPrivate();

  const [activeTab, setActiveTab] = useState(0);

  // Initialize the state with entity data
  const [editedData, setEditedData] = useState({ ...adminData });
  const [editMode, setEditMode] = useState(false);

  const UPDATE_ADMIN_URL = "/admin/admins";
  const UPDATE_ADMIN_PASSWORD_URL = "/admin/admins/password";
  const GET_ADMIN_ROLES = "/admin/admins/roles";
  const PWD_REGEX = /^(?=.*[A-Z]).{6,}$/;
  const phoneNumberRegex = /^\d{8,15}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [firstName, setFirstName] = useState(editedData.firstName);
  const [lastName, setLastName] = useState(editedData.lastName);
  const [email, setEmail] = useState(editedData.email);
  const [phoneNumber, setPhoneNumber] = useState(editedData.phoneNumber);
  const [role, setRole] = useState(editedData.roleId);
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState(editedData.address);
  const [pwd, setPwd] = useState("");

  const [matchPwd, setMatchPwd] = useState("");

  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLasttName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validRole, setValidRole] = useState(true);
  const [validPwd, setValidPwd] = useState(true);
  const [validMatch, setValidMatch] = useState(true);

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const getRoles = async () => {
    try {
      const response = await axiosPrivate.get(GET_ADMIN_ROLES);
      setRoles(response.data.data);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || "An unexpected error occurred."
      );
      setErrorOpen(true);
    }
  };
  useEffect(() => {
    getRoles();
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    fetchData({ pageIndex: 0, pageSize: 10 });
    onClose();
    setEditMode(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage(""); // Reset the error message when closing the popup
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prevState) => !prevState);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    setEditedData({ ...adminData });
    setFirstName(adminData.firstName);
    setLastName(adminData.lastName);
    setEmail(adminData.email);
    setPhoneNumber(adminData.phoneNumber);
    setRole(adminData.roleId);
    setAddress(adminData.address);
    setPwd("");
    setMatchPwd("");
  }, [adminData]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setValidPhoneNumber(phoneNumberRegex.test(phoneNumber));
  }, [phoneNumber]);

  const handleCancel = () => {
    onClose();
    setEditMode(false);
    setFirstName(editedData.firstName);
    setLastName(editedData.lastName);
    setEmail(editedData.email);
    setPhoneNumber(editedData.phoneNumber);
    setRole(editedData.roleId);
    setAddress(editedData.address);
    setPwd("");
    setMatchPwd("");
    setActiveTab(0);
  };

  const handleEditCancel = () => {
    setEditMode(false);
    setFirstName(editedData.firstName);
    setLastName(editedData.lastName);
    setEmail(editedData.email);
    setPhoneNumber(editedData.phoneNumber);
    setRole(editedData.roleId);
    setAddress(editedData.address);
    setPwd("");
    setMatchPwd("");
    setActiveTab(0);
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();
    const v1 = emailRegex.test(email);
    if (!v1) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
    if (!firstName.length) {
      setValidFirstName(false);
    } else {
      setValidFirstName(true);
    }
    if (!lastName.length) {
      setValidLasttName(false);
    } else {
      setValidLasttName(true);
    }
    if (typeof role !== "number") {
      setValidRole(false);
    } else {
      setValidRole(true);
    }

    if (
      !v1 ||
      !firstName.length ||
      !lastName.length ||
      !validPhoneNumber ||
      !validRole
    ) {
      return;
    }
    try {
      const response = await axiosPrivate.put(
        `${UPDATE_ADMIN_URL}/${adminData.id}`,
        JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          roleId: role,
          address: address,
        })
      );
      setSuccessOpen(true);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message || "An unexpected error occurred."
      );
      setErrorOpen(true);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const v2 = PWD_REGEX.test(pwd);

    if (!v2 || !validMatch) {
      return;
    }

    try {
      const response = await axiosPrivate.patch(
        UPDATE_ADMIN_PASSWORD_URL,
        JSON.stringify({
          id: adminData.id,
          newPassword: pwd,
          confirmPassword: matchPwd,
        })
      );
      setSuccessOpen(true);
    } catch (error) {
      setErrorMessage("Error Editing");
      setErrorOpen(true);
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "45%", maxWidth: "703px" },
      }}
      anchor="right"
      open={open}
    >
      <div className="p-[30px] w-full h-full">
        <div className="flex justify-between items-center mb-[22px]">
          <h1 className="text-[18px] text-[1C1C1E] font-['Poppins-SemiBold']">
            {adminData.firstName} {adminData.lastName}
          </h1>
          <img
            src={closeIcon}
            alt=""
            style={{
              cursor: "pointer",
              fontSize: "18px",
              color: "#666",
            }}
            onClick={handleCancel}
          />
        </div>
        <hr className="mb-[30px]" />
        {editMode ? (
          <>
            <div className="flex justify-between border-b-[1px] border-[#CFFD6E] mb-[50px]">
              <button
                className={`text-[18px] flex justify-center w-[50%] py-2 ${
                  activeTab === 0
                    ? "border-b-[1px] border-black text-black"
                    : "text-[#A5A3A9]"
                }`}
                onClick={() => setActiveTab(0)}
              >
                Personal Information
              </button>
              <button
                className={`text-[18px] flex justify-center w-[50%] py-2 ${
                  activeTab === 1
                    ? "border-b-[1px] border-black text-black"
                    : "text-[#A5A3A9]"
                }`}
                onClick={() => setActiveTab(1)}
              >
                Change Password
              </button>
            </div>
            {activeTab === 0 && (
              <form
                autoComplete="off"
                className="flex flex-col justify-between h-[calc(100%-153px)] "
              >
                <div className="flex flex-wrap items-start">
                  <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[42px]">
                    <label
                      htmlFor="firstname"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      First Name
                    </label>
                    <input
                      className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                      value={firstName}
                      type="text"
                      id="firstname"
                      autoComplete="off"
                      placeholder="Enter First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <p
                      id="confirmnote"
                      className={!validFirstName ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      This field is required.
                    </p>
                  </div>
                  <div className="lg:w-[47%] w-full max-w-[460px] mb-[42px]">
                    <label
                      htmlFor="lastname"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      Last Name
                    </label>
                    <input
                      className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                      value={lastName}
                      type="text"
                      id="lastname"
                      autoComplete="off"
                      placeholder="Enter Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <p
                      id="confirmnote"
                      className={!validLastName ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      This field is required.
                    </p>
                  </div>
                  <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[42px]">
                    <label
                      htmlFor="email"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      Email
                    </label>
                    <input
                      className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                      value={email}
                      type="email"
                      id="email"
                      autoComplete="off"
                      placeholder="Enter Admin Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <p
                      id="confirmnote"
                      className={!validEmail ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Invalid email address.
                    </p>
                  </div>
                  <div className="lg:w-[47%] w-full max-w-[460px] mb-[42px]">
                    <label
                      htmlFor="phonenumber"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      Phone Number
                    </label>
                    <input
                      className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                      value={phoneNumber}
                      type="text"
                      id="phonenumber"
                      autoComplete="off"
                      placeholder="Enter Phone Number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                    <p
                      id="confirmnote"
                      className={
                        !validPhoneNumber ? "instructions" : "offscreen"
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Phone number must be between 8 and 15 digits.
                    </p>
                  </div>
                  <div className="lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[42px]">
                    <label
                      htmlFor="role"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      {" "}
                      Select Role
                    </label>
                    <Select
                      label="Role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      fullWidth
                      margin="normal"
                      sx={{
                        borderRadius: "12px",
                        padding: "12px 0",
                        width: "100%",
                        height: "54px",
                        fontSize: "14px",
                        color: "#A5A3A9",
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Role
                      </MenuItem>
                      {roles.map((role) => (
                        <MenuItem value={role.id}>{role.title}</MenuItem>
                      ))}
                    </Select>
                    <p
                      id="confirmnote"
                      className={!validRole ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      This field is required.
                    </p>
                  </div>
                  <div className="lg:w-[47%] w-full max-w-[460px] mb-[42px]">
                    <label
                      htmlFor="address"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      Address (optional)
                    </label>
                    <input
                      className="border border-[#DCDCDC] px-[12px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px] placeholder:font-['Poppins-regular']"
                      value={address}
                      type="text"
                      id="address"
                      autoComplete="off"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex justify-end pb-[34px]">
                  <button
                    className="mr-[24px] w-[140px] py-[16px] border border-[#ED674B] text-[#ED674B] rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </button>
                  <button
                    // disabled={!validPwd || !validMatch ? true : false}
                    className=" disabled:bg-[#A5A3A9] bg-[#03A7FF] w-[140px] py-[16px] text-white  rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
                    onClick={handleInfoSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
            {activeTab === 1 && (
              <form
                autoComplete="off"
                className="flex flex-col justify-between h-[calc(100%-153px)] "
              >
                <div className="flex flex-wrap items-start">
                  <div className="relative lg:w-[47%] w-full max-w-[460px] lg:mr-[18px] mb-[42px]">
                    <label
                      htmlFor="password"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      New password
                    </label>

                    <input
                      className="border border-[#DCDCDC] pl-[12px] pr-[33px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px]"
                      type={isPasswordVisible ? "text" : "password"}
                      id="password"
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                      aria-invalid={validPwd ? "false" : "true"}
                      aria-describedby="pwdnote"
                      autoComplete="off"
                      placeholder="&#xf023; Enter your password"
                    />
                    <button
                      className="absolute top-[48px] right-0 px-4 text-[#A7A7A7]"
                      onClick={togglePasswordVisibility}
                      type="button"
                    >
                      {isPasswordVisible ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>

                    <p
                      id="pwdnote"
                      className={!validPwd ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Use 6 characters or more and must include at least 1
                      uppercase letters.
                    </p>
                  </div>

                  <div className="relative lg:w-[47%] w-full max-w-[460px] mb-[42px]">
                    <label
                      htmlFor="confirm_pwd"
                      className="block text-[#1C1C1E] text-[16px] mb-[6px]"
                    >
                      Confirm your new password
                    </label>
                    <input
                      className="border border-[#DCDCDC] pl-[12px] pr-[33px] py-[16px] rounded-[12px] w-full h-[54px] placeholder:text-[#A5A3A9] placeholder:text-[14px]"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      id="confirm_pwd"
                      onChange={(e) => setMatchPwd(e.target.value)}
                      value={matchPwd}
                      required
                      aria-invalid={validMatch ? "false" : "true"}
                      aria-describedby="confirmnote"
                      autoComplete="off"
                      placeholder="&#xf023; Confirm your password"
                    />
                    <button
                      className="absolute top-[48px] right-0 px-4 text-[#A7A7A7]"
                      onClick={toggleConfirmPasswordVisibility}
                      type="button"
                    >
                      {isConfirmPasswordVisible ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )}
                    </button>
                    <p
                      id="confirmnote"
                      className={!validMatch ? "instructions" : "offscreen"}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Must match the first password input field.
                    </p>
                  </div>
                </div>

                <div className="flex justify-end pb-[34px]">
                  <button
                    className="mr-[24px] w-[140px] py-[16px] border border-[#ED674B] text-[#ED674B] rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </button>
                  <button
                    // disabled={!validPwd || !validMatch ? true : false}
                    className=" disabled:bg-[#A5A3A9] bg-[#03A7FF] w-[140px] py-[16px] text-white  rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
                    onClick={handlePasswordSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </>
        ) : (
          <div className="flex flex-col justify-between h-[calc(100%-60px)]">
            <div className="flex justify-between flex-wrap">
              <div className="w-[48%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">First Name</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {editedData.firstName}
                </div>
              </div>
              <div className="w-[50%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">Last Name</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {editedData.lastName}
                </div>
              </div>
              <div className="w-[48%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">Email</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {editedData.email}
                </div>
              </div>
              <div className="w-[50%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">Phone Number</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {editedData.phoneNumber}
                </div>
              </div>
              <div className="w-[48%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">Select Role</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {roles.find((role)=> editedData.roleId == role.id)?.title}
                </div>
              </div>
              <div className="w-[50%] pb-[42px]">
                <p className="text-[#1C1C1E] text-[16px]">Address</p>
                <div className="overflow-hidden py-[17px] h-[54px] px-[12px] bg-[#F9F9F9] rounded-[12px] text-[#1C1C1E] text-[14px]">
                  {editedData.address ? editedData.address : "--"}
                </div>
              </div>
            </div>
            <div className="flex justify-end pb-[34px]">
              <button
                // startIcon={<SaveIcon />}
                onClick={handleEdit}
                className="disabled:bg-[#A5A3A9] bg-[#03A7FF] w-[140px] py-[16px] text-white  rounded-[12px] text-[15px] leading-[16px] font-['Poppins-SemiBold']"
              >
                Edit
              </button>
            </div>
          </div>
        )}
        <SuccessPopup
          open={successOpen}
          message="Edited Successfully"
          onClose={handleSuccessClose}
        />

        <ErrorPopup
          open={errorOpen}
          message={errorMessage}
          onClose={handleErrorClose}
        />
      </div>
    </Drawer>
  );
};

export default EditAdminDialog;
