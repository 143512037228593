import { Outlet } from 'react-router-dom';
import SideNavBar from '../sideNavBar/sideNavBar.component';

const SidebarLayout = () => (
  <>
    <div className="flex h-full">
        <div className="nav-section">
            <SideNavBar />
        </div>
    <div className="body-section bg-[#F9F9F9]">
        <Outlet />
    </div>
    </div>
  </>
);
export default SidebarLayout