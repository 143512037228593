// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/login-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-bg {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.login-bg .layer {
  background: rgba(68, 80, 3, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

input::placeholder {
  font-family: "FontAwesome", "Poppins-regular";
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.styles.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EAEA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AACI;EACI,gCAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AACR;;AAEA;EACI,6CAAA;AACJ","sourcesContent":[".login-bg{\n    background-image: url(../../assets/login-bg.jpg);\n    // height: 100%;\n    background-position:center;\n    background-repeat: no-repeat;\n    background-size: cover;\n    position: relative;\n    .layer{\n        background: rgba(68, 80, 3, 0.3);\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n    }\n}\ninput::placeholder{\n    font-family: 'FontAwesome', 'Poppins-regular';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
